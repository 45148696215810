import * as React from "react"

import cx from "classnames"
import Menu from "../Menu"
import Footer from "../Footer"
import { infoFooter } from "../../mock/data"

const PrivacyPolicies: React.FC = () => {
  const gridClasses = cx("m-auto main-container")
  const containerClasses = cx("flex flex-col", global.pagesDPCandPP)
  const concat = `mailto:${infoFooter.email}`
  return (
    <>
      <div className="bg-elephant text-white font-barlow">
        <div className={gridClasses}>
          <Menu pageName="politicas-de-privacidade" />
          <div className="flex justify-center">
            <div className={containerClasses}>
              <p className="font-bold text-4xl m-0 text-left">
                POLÍTICA DE PRIVACIDADE
              </p>
              <p className="font-light pt-8 m-0 text-justify">
                <span className="font-bold">Informações: </span>a presente
                política de privacidade e proteção de dados pessoais tem por
                objetivo informar a você, titular, como tratamos os seus dados
                pessoais e quais as medidas de segurança adotamos no tratamento.
              </p>
              <p className="mt-4 mb-0 font-bold">Quais dados tratamos:</p>
              <p className="">
                <span className="font-bold">a)</span> Dados que você nos fornece
                – ao navegar por nosso website, tratamos os dados que você nos
                fornece, como nome, e-mail, currículo e telefone.
              </p>
              <p className="">
                <span className="font-bold">b)</span> Dados que coletamos
                automaticamente – quando você utiliza nosso website, coletamos
                automaticamente informações sobre os seus dispositivos digitais,
                dados de navegação e geolocalização, tais como IP, navegador,
                páginas acessadas, tempo de acesso e outros dados de cookies. A
                maioria dos dispositivos digitais permite que você controle
                quais dados de geolocalização e demais cookies serão
                compartilhados conosco, de modo que fica a seu critério
                desabilitar o compartilhamento deste tipo de informação.
              </p>
              <p className="">
                <span className="font-bold">Tempo de tratamento: </span> os seus
                dados pessoais serão eliminados pela nossa empresa quando
                deixarem de ser necessários para as finalidades previamente
                definidas nesta Política ou mediante a sua solicitação, desde
                que não conflite com o cumprimento de obrigações
                legais/regulatórias, para o exercício regular de direito ou para
                o legítimo interesse do controlador.
              </p>
              <p className="mt-4 mb-0 font-bold">Finalidades:</p>
              <p className="">
                <span className="font-bold">a)</span> Atendimento ao usuário –
                possibilitar o contato com o usuário que buscar mais informações
                sobre nosso serviço pelo formulário “Contate-nos”
              </p>
              <p className="">
                <span className="font-bold">b)</span> Marketing – estabelecer um
                canal de comunicação/promoção com clientes e leads, para
                fornecer referências e recomendações sobre nossos produtos e
                serviços.
              </p>
              <p className="">
                <span className="font-bold">c)</span> Análise de dados –
                analisar operações de negócios para descrever, prever e melhorar
                o desempenho em nossas ferramentas digitais para fins de
                fornecer a melhor experiência ao usuário.
              </p>
              <p className="">
                <span className="font-bold">d)</span> Execução do contrato –
                garantir as obrigações contratuais, legais e regulatórias
                firmadas na relação contratual entre as partes.
              </p>
              <p className="mt-4 mb-0 font-bold">Bases legais:</p>
              <p className="m-0">
                <span className="font-bold">a)</span> Ao informar os dados
                solicitados no formulário disponibilizado na página “Vagas”{" "}
                <a
                  href="https://jobs.quickin.io/enesolucoes/jobs)"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  (https://jobs.quickin.io/enesolucoes/jobs)
                </a>
                , a base legal será a necessidade para execução de procedimentos
                preliminares relacionados a contrato do qual o titular será
                parte, de modo que possamos avaliar os dados informados para a
                candidatura desejada. A finalidade de cada dado informado neste
                campo pode ser verificada no tópico “Recrutamento e seleção”
                abaixo
              </p>
              <p className="m-0">
                <span className="font-bold">b)</span> Ao informar seu nome e
                e-mail no formulário “Contate-nos”, a base legal será o legítimo
                interesse, para que possamos entrar em contato com você para lhe
                solucionar dúvidas e retornar sobre sugestões, críticas ou
                demais assuntos de tema da mensagem enviada.
              </p>
              <p className="m-0">
                <span className="font-bold">c)</span> Quanto aos dados coletados
                automaticamente, são fundados no legítimo interesse do
                controlador, para descrever, prever e melhorar o desempenho em
                nossas ferramentas digitais para fins de fornecer a melhor
                experiência ao usuário.
              </p>
              <p className="m-0">
                <span className="font-bold">d)</span> Em caso de necessidade de
                cumprimento de obrigação legal ou regulatória pelo controlador,
                a base legal será esta, assim como nos casos de exercício
                regular de direito, exercício regular de direitos em processo ou
                tutela da saúde.
              </p>

              <p className="mt-4 mb-0 font-bold">Seus direitos:</p>
              <p className="m-0">
                <span className="font-bold">a)</span> Solicitar a confirmação de
                que estamos tratando seus dados pessoais.
              </p>
              <p className="m-0">
                <span className="font-bold">b)</span> Acessar de forma
                facilitada e gratuita a cópia de seus dados tratados por nós.
              </p>
              <p className="m-0">
                <span className="font-bold">c)</span> Solicitar a correção de
                dados incompletos, inexatos ou desatualizados.
              </p>
              <p className="m-0">
                <span className="font-bold">d)</span> Solicitar a anonimização
                de seus dados, assim como seu bloqueio ou eliminação, quando
                tratados em desconformidade com a Lei Geral de Proteção de
                Dados.
              </p>
              <p className="m-0">
                <span className="font-bold">e)</span> Solicitar a portabilidade
                de seus dados pessoais para outro fornecedor de
                produtos/serviços.
              </p>
              <p className="m-0">
                <span className="font-bold">f)</span> Retirar eventual
                consentimento dado em momento anterior.
              </p>
              <p className="m-0">
                <span className="font-bold">g)</span> Solicitar a eliminação de
                seus dados pessoais tratados tendo como base legal o
                consentimento.
              </p>
              <p className="m-0">
                <span className="font-bold">h)</span> Questionar para quais
                empresas e órgãos públicos compartilhamos seus dados, assim como
                as finalidades deste compartilhamento.
              </p>
              <p className="m-0">
                <span className="font-bold">i)</span> Solicitar informação sobre
                a possibilidade de não fornecer consentimento e suas
                consequências.
              </p>

              <p className="mt-4 mb-0">
                <span className="font-bold">Recrutamento e selecção:</span>{" "}
                passamos a informar a finalidade de cada dado solicitado na
                página “Vagas” de nosso website.
              </p>
              <p className="m-0">
                <span className="font-bold">a)</span>
                Nome completo – necessário para identificar o candidato, assim
                como para que seja realizada busca de seu perfil junto ao
                LinkedIn{" "}
                <a
                  href="https://www.linkedin.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  (https://www.linkedin.com)
                </a>
              </p>
              <p className="m-0">
                <span className="font-bold">b)</span> Cargo pretendido –
                necessário para verificar se o perfil do candidato se adequa à
                vaga pretendida e se a vaga informada está disponível;
              </p>
              <p className="m-0">
                <span className="font-bold">c)</span> Data de nascimento –
                necessário para analisar o perfil comportamental do candidato e
                comparar experiência e bagagem profissional;
              </p>
              <p className="m-0">
                <span className="font-bold">d)</span> Pretensão salarial –
                necessário para identificar se o objetivo salarial pretendido
                pelo candidato condiz com o valor que a empresa está disposta a
                oferecer;
              </p>
              <p className="m-0">
                <span className="font-bold">e)</span> E-mail – necessário para
                que a empresa entre em contato e envie o convite para a
                entrevista;
              </p>
              <p className="m-0">
                <span className="font-bold">f)</span> Telefone – necessário para
                que a empresa entre em contato e converse sobre a vaga com o
                candidato;
              </p>
              <p className="m-0">
                <span className="font-bold">g)</span> Cidade – utilizado para
                que seja identificada eventual necessidade de envio de
                equipamentos e outros itens para o candidato em outra cidade;
              </p>
              <p className="m-0">
                <span className="font-bold">h)</span> Resumo – possibilita que o
                candidato escreva um resumo sobre suas habilidades e
                qualificações profissionais, assim como outras informações que
                julgar pertinentes para sua candidatura;
              </p>
              <p className="m-0">
                <span className="font-bold">i)</span> Formação – utilizado para
                verificar se a formação acadêmica e o nível escolar do candidato
                condizem com a vaga à qual se candidatou;
              </p>
              <p className="m-0">
                <span className="font-bold">j)</span> Experiência – possibilita
                ao candidato informar em quais empresas e períodos trabalhou,
                assim como quais cargos ocupou, de modo que seja possibilitada
                uma análise mais completa de seu histórico e experiências;
              </p>
              <p className="m-0">
                <span className="font-bold">k)</span> Idiomas – possibilita ao
                candidato informar seus idiomas, de modo que seja verificado se
                atende à eventuais requisitos de determinada vaga;
              </p>
              <p className="m-0">
                <span className="font-bold">l)</span> Currículo – possibilita ao
                candidato anexar arquivo contendo seu currículo, para que seja
                realizada uma análise mais completa de seu perfil profissional;
              </p>
              <p className="m-0">
                <span className="font-bold">m)</span> Disponibilidade de início
                – necessário para verificar se o prazo de disponibilidade do
                candidato condiz com as necessidades de determinada vaga.
              </p>
              <p className="mt-4">
                <span className="font-bold">Compartilhamento de dados: </span>os
                dados coletados serão compartilhados de forma anonimizada com a
                plataforma Google Analytics, de modo que seja possível estudar
                formas mais assertivas de veiculação de publicidade, sempre
                tendo por base os dados anônimos. Os dados informados nos campos
                disponibilizados na página “Vagas” serão compartilhados com a
                empresa Quickin, responsável pela plataforma em que é realizado
                o recrutamento e seleção. Para maiores informações sobre a
                Quickin, acesse:{" "}
                <a
                  href="https://www.quickin.io/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  https://www.quickin.io/
                </a>
                .
              </p>
              <p className="mt-4">
                <span className="font-bold">Medidas de segurança: </span>{" "}
                adotamos medidas de segurança para garantir a maior proteção
                possível ao tratamento realizado aos seus dados, com Firewall
                Fortigate 50E.
              </p>
              <p className="mt-4">
                <span className="font-bold">Meio de contato: </span> Para
                esclarecimentos ou solicitações, você pode entrar em contato com
                o encarregado de dados de nossa empresa pelo e-mail{" "}
                <a
                  className="text-white font-bold sm:text-xs md:text-base lg:text-base xl:text-base"
                  href={concat}
                >
                  {infoFooter.email}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default PrivacyPolicies
